<template>
  <div>
    <h2 class="fz-16 mt-6 mb-4">Choose Token</h2>
    <div class="coin-container">
      <div
        @click="onSelect(it)"
        class="coin-label py-2 px-4 cursor-p d-flex space-btw mr-2 mb-2"
        :class="{
          active: value == it.label,
        }"
        v-for="(it, i) in coinList"
        :key="i"
      >
        <div class="al-c flex-1">
          <v-icon size="16" color="'#555'"
            >mdi-{{
              value == it.label
                ? "circle-slice-8"
                : "checkbox-blank-circle-outline"
            }}</v-icon
          >
          <img
            class="ml-3"
            style="vertical-align: middle"
            :src="it.img"
            width="40"
          />
          <div class="fz-12 ml-1">
            <div
              class="fz-14"
              :class="{
                'color-1': value == it.label,
              }"
            >
              {{ it.name }}
            </div>
            <div>{{ it.showLabel }}</div>
          </div>
          <div class="ml-auto fz-14 gray">Available: {{ showBalance(it) }}</div>

          <v-tooltip
            top
            max-width="300"
            nudge-top="5"
            v-if="it.label == 'USDCE'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-auto" size="18" v-bind="attrs" v-on="on"
                >mdi-alert-circle-outline</v-icon
              >
            </template>
            <div style="line-height: normal" class="py-2">
              The USDC.e is a "bridged form Ethereum USDC", which is bridged
              from the Ethereum blockchain. You can also opt to purchase it from
              Uniswap
            </div>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { providers } from "ethers";
import { mapGetters } from "vuex";

import { scrollUSDC, scrollUSDT, scrollDAI } from "@/contracts/contracts-addr";
import { ICoin__factory } from "@4everland-contracts";
import { formatEther, formatUnits } from "ethers/lib/utils";
export default {
  props: {
    chainId: {
      type: Number,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      selected: "ETH",
      coinList: [
        {
          label: "ETH",
          showLabel: "ETH",
          name: "ETH",
          balance: "0.00000",
          img: "/img/svg/pay/eth.svg",
        },
        {
          label: "USDC",
          showLabel: "USDC",
          name: "USDC Coin",
          balance: "0.00000",

          img: "/img/svg/pay/usdc.svg",
        },
        {
          label: "USDT",
          showLabel: "USDT",
          name: "Tether USD",
          balance: "0.00000",

          img: "/img/svg/pay/usdt.svg",
        },
        {
          label: "DAI",
          showLabel: "DAI",
          name: "Dai Stablecoin",
          balance: "0.00000",
          img: "/img/svg/pay/dai.svg",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["walletObj"]),
    signer() {
      let provider = new providers.Web3Provider(this.walletObj);
      return provider.getSigner();
    },
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    onSelect(it) {
      this.$emit("onSelectCoin", it);
    },
    async getBalance() {
      this.getEthBalance();
      this.getUSDCBalance();
      this.getUSDTBalance();
      this.getDAIBalance();
    },

    async getEthBalance() {
      let provider = new providers.Web3Provider(this.walletObj);
      const account = await provider.getSigner().getAddress();
      const balance = await provider.getBalance(account);
      this.coinList[0].balance = formatEther(balance);
    },
    async getUSDCBalance() {
      let provider = new providers.Web3Provider(this.walletObj);
      const account = await provider.getSigner().getAddress();
      const ERC20 = ICoin__factory.connect(scrollUSDC, this.signer);
      const balance = await ERC20.balanceOf(account);
      const decimals = await ERC20.decimals();
      this.coinList[1].balance = formatUnits(balance, decimals);
    },
    async getUSDTBalance() {
      let provider = new providers.Web3Provider(this.walletObj);
      const account = await provider.getSigner().getAddress();
      const ERC20 = ICoin__factory.connect(scrollUSDT, this.signer);
      const balance = await ERC20.balanceOf(account);
      const decimals = await ERC20.decimals();
      this.coinList[2].balance = formatUnits(balance, decimals);
    },
    async getDAIBalance() {
      let provider = new providers.Web3Provider(this.walletObj);
      const account = await provider.getSigner().getAddress();
      const ERC20 = ICoin__factory.connect(scrollDAI, this.signer);
      const balance = await ERC20.balanceOf(account);
      const decimals = await ERC20.decimals();
      this.coinList[3].balance = formatUnits(balance, decimals);
    },

    showBalance(it) {
      if (it.balance == 0) return Number(it.balance).toFixed(5);
      if (it.label == "ETH") return Number(it.balance).toFixed(5);
      return it.balance;
    },
  },
};
</script>

<style lang="scss" scoped>
.coin-label {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #cbd5e1;
}
.coin-label.active {
  font-weight: bold;
  border: 1px solid #735ea1;
}
.v-tooltip__content {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
}
.v-tooltip__content::after {
  display: block;
  content: "";
  position: absolute;
  right: 52px;
  bottom: -20px;
  border: 10px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.9);
}
.coin-container {
  margin-bottom: 200px;
}
</style>
