<template>
  <div class="deposite-container">
    <div class="deposite-content flex-1 h-flex">
      <div class="purchase-plate">
        <h2 class="fz-16">Deposit</h2>
        <div class="deposite-section mt-4">
          <div class="deposite-control">
            <input
              maxlength="8"
              class="deposite-input"
              v-model="landAmount"
              @input="handleInput"
              type="text"
            />
            <span class="num">,000,000</span>
            <span class="deposite-btn ta-c fz-14">LAND</span>
          </div>
          <div class="mt-1 fz-12">
            1000,000 LAND is equivalent to 1 USD, and it cannot be withdrawn.
          </div>
        </div>
      </div>
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="fw-b ta-r">Calculator</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="min-height: 500px">
              <resource-counter
                @estimateInput="estimateInput"
                :inputVal="landAmount"
              ></resource-counter>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div class="act-control pos-r h-flex">
      <h2 class="fz-16">Network</h2>
      <pay-network @onNetwork="onNetwork" />
      <pay-coin
        v-model="coinSelect"
        class="flex-1"
        :chainId="chainId"
        @onSelectCoin="handleSelectCoin"
      ></pay-coin>

      <div class="pay-confirm-container">
        <div class="pay-confirm pa-4 al-c space-btw">
          <div class="amout-info">
            <div class="fz-16 fw-b">Total</div>

            <div class="mt-1">
              <span class="amount fw-b">{{ displayPrice }}</span>
              <span class="unit fz-14 ml-1">{{ CoinType }}</span>
            </div>
          </div>
          <!-- eth bsc another payment btn -->

          <div
            class="confirm-btn fw-b cursor-p ta-c"
            v-ripple
            :class="ethDisabled ? 'disabled' : ''"
            v-if="coinSelect == 'ETH'"
            @click="
              () => {
                if (ethDisabled) return '';
                return handleRechargeLand();
              }
            "
          >
            {{ ethInsufficient ? "Insufficient balance" : "Confirm" }}
          </div>
          <!-- other confirm btn -->
          <div
            class="confirm-btn fw-b cursor-p pos-r ta-c"
            :class="disabled ? 'disabled' : ''"
            v-ripple="!checkApproving"
            v-else
            @click="
              () => {
                if (disabled) return '';
                return checkApproving ? '' : handleShowStep();
              }
            "
          >
            <v-btn v-show="checkApproving" text :loading="true" color="#735EA1">
            </v-btn>
            <span v-show="!checkApproving">
              {{ confirmText }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <recharge-step-dialog
      ref="rechargeDialog"
      :txHash="txHash"
      :step="step"
      :approving="approving"
      :depositing="depositing"
      @handleApprove="handleApprove"
      @handleConfirm="handleRechargeLand"
      @handleClose="handleClose"
    ></recharge-step-dialog>
  </div>
</template>

<script>
import payNetwork from "./components/pay-network.vue";
import payCoin from "./components/pay-coin.vue";
import resourceCounter from "./components/resource-counter.vue";
import rechargeStepDialog from "./components/recharge-step-dialog.vue";
import { mapGetters, mapState } from "vuex";
import { BigNumber, providers } from "ethers";
import debounce from "@/utils/debounce";
import {
  parseUnits,
  parseEther,
  formatEther,
  solidityPack,
  formatUnits,
} from "ethers/lib/utils";
import {
  ICoin__factory,
  Land__factory,
  IQuoter__factory,
  UNILand__factory,
} from "@4everland-contracts";
import uidToEuid from "@/utils/uid2euid";
import mixin from "./mixin";
const uint256Max = BigNumber.from("1").shl(256).sub(1);
export default {
  mixins: [mixin],
  components: {
    payNetwork,
    resourceCounter,
    rechargeStepDialog,
    payCoin,
  },
  data() {
    return {
      coinSelect: "ETH",
      landAmount: "",
      allowance: BigNumber.from("0"),
      usdcAmount: BigNumber.from("0"),
      ethAmount: BigNumber.from("0"),
      curAmountDecimals: 18,
      chainId: this.$inDev ? 80001 : 137,
      rechargeSuccess: false,
      checkApproving: false,
      approving: false,
      depositing: false,
      btnDisabled: true,
      txHash: "",
      balance: 0,
    };
  },
  computed: {
    ...mapState({
      connectAddr: (s) => s.connectAddr,
      uid: (s) => s.userInfo.uid,
    }),
    ...mapGetters(["walletObj"]),
    signer() {
      let provider = new providers.Web3Provider(this.walletObj);
      return provider.getSigner();
    },
    isApproved() {
      return (
        this.allowance.eq(uint256Max) || this.allowance.gte(this.usdcAmount)
      );
    },
    displayPrice() {
      if (this.coinSelect == "ETH") {
        return (formatEther(this.ethAmount) * 1).toFixed(5);
      } else {
        return this.usdcAmount.toString();
      }
    },
    confirmText() {
      if (this.insufficient) return "Insufficient balance";
      if (this.isApproved) return "Confirm";
      return "Approve";
    },
    payAmounts() {
      if (!this.landAmount) return BigNumber.from("0");
      let amoutU = parseUnits(this.landAmount, 24).div(1e6);
      const subDecimal = 18 - this.curAmountDecimals;
      if (subDecimal > 0) {
        amoutU = amoutU.div(BigNumber.from(10 ** subDecimal));
      }
      return amoutU;
    },
    ERC20() {
      return ICoin__factory.connect(this.coinAddr, this.signer);
    },
    LandRecharge() {
      return Land__factory.connect(this.landRechargeAddr, this.signer);
    },
    ethLandRecharge() {
      return UNILand__factory.connect(this.landRechargeAddr, this.signer);
    },
    curChainInfo() {
      return this.chainAddrs.find((it) => it.chainId == this.chainId);
    },
    landRechargeAddr() {
      return this.curChainInfo?.landRecharge;
    },
    coinAddr() {
      const coinType = this.coinSelect.toLowerCase();
      return this.curChainInfo?.coin[coinType];
    },
    step() {
      if (this.rechargeSuccess) return 4;
      if (this.isApproved) return 2;
      return 1;
    },
    euid() {
      return uidToEuid(this.uid);
    },
    CoinType() {
      return this.coinSelect;
    },
    disabled() {
      return (
        this.payAmounts.toString() == "0" ||
        this.btnDisabled ||
        this.chainId != 534352 ||
        this.insufficient
      );
    },
    ethDisabled() {
      return this.ethAmount.toString() == "0" || this.ethInsufficient;
    },
    ethInsufficient() {
      if (this.balance == 0) return true;
      return Number(this.balance) < formatEther(this.ethAmount);
    },
    insufficient() {
      if (this.balance == 0) return true;
      return Number(this.balance) < this.landAmount;
    },
  },

  async mounted() {
    this.walletObj.on("accountsChanged", (val) => {
      this.$store.commit("SET_CONNECT_ADDR", val[0]);
      this.checkApproved();
    });
    await this.getBalance();
  },
  methods: {
    onNetwork(chainId) {
      if (this.chainAddrs.findIndex((it) => it.chainId == chainId) == -1) {
        this.btnDisabled = true;
      } else {
        this.btnDisabled = false;
      }
      this.chainId = chainId;
      this.coinSelect = "ETH";
      this.checkApproved();
    },
    async handleRechargeLand() {
      this.depositing = true;
      try {
        let receipt = "";
        if (this.coinSelect == "ETH") {
          if (!this.ethAmount) return;
          this.$loading();
          const tx = await this.ethLandRecharge.mintByETH(this.euid, {
            value: this.ethAmount,
          });
          receipt = await tx.wait();
          this.$loading.close();
          this.$router.push("/success/" + receipt.transactionHash);
        } else {
          const tx = await this.LandRecharge.mint(
            this.coinAddr,
            this.euid, // euid
            this.payAmounts
          );
          receipt = await tx.wait();
          console.log(receipt);
        }
        this.rechargeSuccess = true;
        this.txHash = receipt.transactionHash;
        this.transactionCache(receipt.transactionHash);
      } catch (error) {
        this.onErr(error);
      }
      this.depositing = false;
    },
    async checkApproved() {
      if (!this.landRechargeAddr) return;
      this.checkApproving = true;
      if (!this.connectAddr) {
        await this.getAccount();
      }
      try {
        const allowance = await this.ERC20.allowance(
          this.connectAddr,
          this.landRechargeAddr
        );
        // console.log(allowance);
        this.curAmountDecimals = await this.ERC20.decimals();
        // console.log(allowance, "allowance");
        this.allowance = allowance;
      } catch (error) {
        console.log(error);
        // if locked wallet  throw Error account, need get account
        this.getAccount();
      }
      this.checkApproving = false;
    },
    async handleApprove() {
      this.approving = true;
      try {
        let gas = await this.ERC20.estimateGas.approve(
          this.landRechargeAddr,
          uint256Max
        );
        console.log("gas", gas);
        let gasPrice = await this.ERC20.provider.getGasPrice();

        const tx = await this.ERC20.approve(this.landRechargeAddr, uint256Max, {
          gasLimit: gas.mul(15).div(10),
          gasPrice: gasPrice.mul(12).div(10),
        });
        console.log("tx", tx);
        const receipt = await tx.wait();
        console.log(receipt);
      } catch (error) {
        this.onErr(error);
      }
      this.approving = false;
      this.checkApproved();
    },
    handleShowStep() {
      this.$refs.rechargeDialog.showDialog = true;
    },
    async handleSelectCoin(item) {
      this.coinSelect = item.label;
      this.balance = item.balance;
      if (this.coinSelect == "ETH") {
        this.usdc2eth();
      } else {
        await this.checkApproved();
      }
    },
    handleClose() {
      if (this.rechargeSuccess) {
        this.landAmount = "";
        this.rechargeSuccess = false;
      }
    },
    async getAccount() {
      await this.$store.dispatch("getWalletAccount");
    },
    onErr(err, retry) {
      if (!err) return console.log("---- err null");
      if (/unknown account/.test(err.message)) {
        return this.getAccount();
      }
      const { data } = err;
      let msg = err.message;
      if (data) {
        msg = data.message || msg;
      }
      if (/repriced/i.test(msg) && /replaced/i.test(msg)) {
        return this.$toast("Transaction was replaced.");
      }
      if (/missing revert data/i.test(msg)) {
        msg = "Network Error";
      } else if (/user rejected/i.test(msg)) {
        msg = "Your transaction has been canceled.";
      } else if (/transaction failed/i.test(msg)) {
        msg = "Transaction Failed";
      } else if (/ipfs/.test(msg) && /invalid params/.test(msg)) {
        msg = "IPFS Storage Expired, extending service duration is required.";
      } else if (
        /exceeds balance/i.test(msg) ||
        msg == "overflow" ||
        /insufficient funds/i.test(msg)
      ) {
        msg = "Insufficient balance in your wallet.";
      } else if (msg.length > 100) {
        const mat = /^(.+)\[/.exec(msg);
        if (mat) msg = mat[1];
      }
      if (/already pending for origin/gi.test(msg)) {
        msg = "Wrong network, please switch your wallet network and try again.";
      }
      if (retry) {
        return this.$confirm(msg, "Network Error", {
          confirmText: "Retry",
        });
      }
      return this.$alert(msg, "Error");
    },
    transactionCache(txHash) {
      let transactionItem = {
        network: this.chainId.toString(),
        landAmount: this.usdcAmount
          .mul((1e18).toString())
          .mul((1e6).toString())
          .toString(),
        amount: this.usdcAmount.mul((1e18).toString()).toString(),
        originalValue:
          this.coinSelect == "ETH" ? this.ethAmount.toString() : "",
        txHash,
        createdAt: +new Date() / 1e3,
        status: "2",
        amountType: this.coinAddr,
      };
      let transactionList = localStorage.getItem("transactionCache");
      if (!transactionList) {
        localStorage.setItem("transactionCache", JSON.stringify([]));
        transactionList = [];
      } else {
        transactionList = JSON.parse(localStorage.getItem("transactionCache"));
      }
      transactionList.unshift(transactionItem);
      localStorage.setItem("transactionCache", JSON.stringify(transactionList));
    },
    estimateInput(val) {
      this.landAmount = val;
      this.handleInput();
    },
    handleInput() {
      this.landAmount = this.landAmount.replace(/[^\d]/g, "");
      if (this.landAmount) {
        this.usdcAmount = BigNumber.from(this.landAmount);
      } else {
        this.usdcAmount = BigNumber.from("0");
        this.ethAmount = BigNumber.from("0");
      }
      if (this.coinSelect == "ETH") {
        debounce(() => {
          this.usdc2eth();
        });
      }
    },

    async usdc2eth() {
      if (this.usdcAmount.eq(BigNumber.from("0"))) {
        this.ethAmount = BigNumber.from("0");
        return;
      }
      if (this.chainId == "534352") {
        this.ethAmount = parseEther(
          (this.usdcAmount.toNumber() / 3000).toFixed(18)
        );
      } else {
        const quoter = IQuoter__factory.connect(
          "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
          this.signer
        );
        const path = solidityPack(
          ["address", "uint24", "address"],
          [
            "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85", // usdc addr
            500, //
            this.coinAddr,
          ]
        );
        const res = await quoter.callStatic.quoteExactOutput(
          path,
          parseUnits(this.usdcAmount.toString(), 6)
        );
        this.ethAmount = res;
      }
    },
    async getBalance() {
      let provider = new providers.Web3Provider(this.walletObj);
      const account = await provider.getSigner().getAddress();
      if (this.CoinType == "ETH") {
        const balance = await provider.getBalance(account);
        this.balance = formatEther(balance);
      } else {
        const balance = await this.ERC20.balanceOf(account);
        const decimals = await this.ERC20.decimals();
        this.balance = formatUnits(balance, decimals);
      }
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
<style lang="scss" scoped>
.deposite-container {
  min-height: 100%;
  align-items: stretch;
}
.deposite-control {
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.deposite-input {
  height: 48px;
  width: 200px;
  background: transparent;
  text-indent: 20px;
  text-align: right;
  font-size: 20px;
  border-radius: 4px 0 0 4px;
  font-family: "DIN Alternate";
}
.num {
  font-family: "DIN Alternate";
  font-size: 20px;
  padding-right: 10px;
  color: #64748b;
}
.deposite-btn {
  padding: 0 15px;
  height: 48px;
  line-height: 48px;
  background: #f1f5f9;
  border-radius: 0 4px 4px 0;
}

.deposite-content {
  border-radius: 8px;
  background: #fff;
  .purchase-plate {
    padding: 18px 16px;
  }
}
.act-control {
  padding: 24px 16px 0;
  border-radius: 8px;
  background: #f3e8ff;
  box-sizing: border-box;
  .pay-confirm-container {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 999;
    // border-radius: 0px 0px 8px 8px;
    background: #735ea1;
    .pay-confirm {
      color: #fff;
      .redeem-trigger {
        color: #735ea1;
      }
      .amount {
        font-size: 28px;
      }
      .unit {
        color: #e2e8f0;
        font-family: "DIN Alternate";
      }
      .confirm-btn {
        color: #735ea1;
        min-width: 150px;
        padding: 15px 20px;
        border-radius: 4px;
        background: #fff;
      }
      .confirm-btn.disabled {
        color: #fff;
        background: #e2e8f0;
      }
      .confirm-tip {
        width: 336px;
        padding: 8px;
        font-size: 12px;
        font-weight: normal;
        top: -65px;
        right: 0;
        border-radius: 4px;
        background: #d8c8ec;
        color: #735ea1;
      }
      .confirm-tip::after {
        display: block;
        content: "";
        position: absolute;
        right: 30%;
        bottom: -20px;
        border: 10px solid transparent;
        border-top-color: #d8c8ec;
      }
    }
  }
}
</style>
