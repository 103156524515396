export const providerAddr = "0x53b10A60F28c1F35025D9dC0773339638c540a67";
export const clientRpc = "https://cbridge-prod2.celer.network";

// src chain eth
export const GoerliRpc = "https://polygon.llamarpc.com";
export const GoerliUSDC = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
export const GoerliUSDT = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const GoerliDAI = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
export const GoerliLandRecharge = "0xCbE56b00d173A26a5978cE90Db2E33622fD95A28";

export const GoerliBridge = "0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820";
export const GoerliSrcChainPayment =
  "0x3cA298d7A98262C0598dd91Ce926f23e51c4b293";
export const GoerliRecharge = "0x81fd435639bb3aB23711c9C03f60a2167634025e";
export const GoerliRegister = "0x5942d5f3a59EbCEa5f82deE35716Eb1B18C19D71";

// src chain bsc
export const ChapelUSDC = "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d";
export const ChapelUSDT = "0x55d398326f99059fF775485246999027B3197955";
export const ChapelDAI = "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3";
export const ChapelLandRecharge = "0xb67Bf3421dd0BcD47C9c197dDEf3d1bEC4263eBd";

export const ChapelBridge = "0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF";
export const ChapelSrcChainPayment =
  "0x3cA298d7A98262C0598dd91Ce926f23e51c4b293";
export const ChapelRecharge = "0x6Eb2E3b63Df577401eE6f926fFD30580021FE222";
export const ChapelRegister = "0x63bAaA3743647fA9324a806FF84294D41C27986c";

// src chain arbitrum one
export const ArbitrumUSDC = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
export const ArbitrumUSDT = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";
export const ArbitrumDAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";
export const ArbitrumLandRecharge =
  "0x598a237eb2c0efe381C3b6a22e36220C2e2C74b6";

export const ArbitrumBridge = "0x1619DE6B6B20eD217a58d00f37B9d47C7663feca";
export const ArbitrumSrcChainPayment =
  "0x3cA298d7A98262C0598dd91Ce926f23e51c4b293";
export const ArbitrumRecharge = "";
export const ArbitrumRegister = "0xAcdcA934E9D4dAA07E4B092fb8395DD872c3221C";

// src chain zksync
export const zkSyncUSDC = "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4";
export const zkSyncUSDT = "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C";
export const zkSyncDAI = "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656";
export const zkSyncLandRecharge = "0x5cCb7dfEA225Da03656d70EDE03f47f57A996db8";

export const zkSyncPayment = "0x036d8A0275D48c0Fa62AB02707C99208b22a96ae";
export const zkRegister = "0x92E0536939D1279f4aEF0d0beA55563bA8c1af94";

// src chain opBNB
export const opBNBRegister = "0x742eAd8e4D06404d9A49945D086752E1f3eb18Ca";

// polygon zk evm
export const polygonZkEVMRegister =
  "0x742eAd8e4D06404d9A49945D086752E1f3eb18Ca";

export const lineaRegister = "0x742eAd8e4D06404d9A49945D086752E1f3eb18Ca";

// zeta-testnet
export const zetaRegister = "0xA6D3027F572BB7e9E141465640bb736ee78a5867";

// optimism chain
export const optimisUSDC = "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85";
export const optimisUSDT = "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58";
export const optimisDAI = "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1";
export const optimisETH = "0x4200000000000000000000000000000000000006";
export const optimismRecharge = "0x19f240DD39FEF12D139a4E0Ae81f44945c06092f";

// scroll chain
export const scrollUSDC = "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4";
export const scrollUSDT = "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df";
export const scrollDAI = "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97";
export const scrollETH = "0x5300000000000000000000000000000000000004";
export const scrollRecharge = "0xfE99cdC31A0D588AB940aB7143533242AEf5276d";

// dst chain
export const MumbaiRegister = "0x7Fe5B4e4AC8817bEBb737053Eb7E945fd9b03c10";
export const MumbaiSafeWallet = "0xbbB3194D73925019194F8b288325bd039fa97880";
export const MumbaiRouter = "0x44DDDef9c1fA0Ed35f64f98062E505a968AaBa9C";
export const MumbaiGovernance = "0xB35F070CC9E3503DCc7AED42E4ECbaA023975382";

export const MumbaiProviderController =
  "0x60849aFC60da8686a33e54D7fAfeff7804BF63E2";
export const MumbaiProviderRegistry =
  "0xBE315C9c13c4D12f4E4e12Ec11aC5123d4E540B7";
export const MumbaiResourcePriceAdaptor =
  "0x598a237eb2c0efe381C3b6a22e36220C2e2C74b6";
export const MumbaiFundPool = "0xEBFEc6F5aE63F10F6721Dd10ebe37a1d3a0a5207";
export const MumbaiBilling = "0xB0325cC6A056B36cf211b9c8CDE32Aff035efa5F";
export const MumbaiARStorageController =
  "0xEeEDEF4aDe21065FE254C7cc4a684f4D7E706419";
export const MumbaiBandwidthController =
  "0x19f240DD39FEF12D139a4E0Ae81f44945c06092f";
export const MumbaiBuildingTimeController =
  "0x2C08a9287A7B37c4fB6e154b3602C930381d5666";
export const MumbaiIPFSStorageController =
  "0xAcdcA934E9D4dAA07E4B092fb8395DD872c3221C";
export const MumbaiDstChainPayment =
  "0x62955f5BF3813aA4ABAC25799dF646C23522a5e9";
export const MumbaiUSDC = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
export const MumbaiUSDCE = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";
export const MumbaiUSDT = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
export const MumbaiDAI = "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063";
export const MumbaiLandRecharge = "0x8f786De53F6140b6c06D191c6fC9767842d2eF99";
